import React from "react"
import Layout from '../components/layout'
import VehicleCompare from "../components/vehicleCompare/VehicleCompare"
import { configurableCompareString } from "../Modules/configurableVehicleInfo"


function Compare(props) {
    const { pageContext } = props
    const { pageContent, theme, dealers, dealerInfo, inventorymanagement, lead_url, site_id } = pageContext
    const { mainVehicle, mainVehicleName,  selectedVehicle,selectedVehicleName, thumbnails } = pageContext

    const seoObject = {mainModel: mainVehicleName, compareModel: selectedVehicleName}
    let compareSEO = pageContent.SEO

    compareSEO.seo.MetaDesc = configurableCompareString(compareSEO.seo.MetaDesc,seoObject)
    compareSEO.seo.MetaDesc = (compareSEO.seo.MetaDesc == "" ? "Vehicle Compare Page" : compareSEO.seo.MetaDesc)

    compareSEO.seo.MetaTitle = configurableCompareString(compareSEO.seo.MetaTitle,seoObject)
    compareSEO.seo.MetaTitle = (compareSEO.seo.MetaTitle == "" ? "Vehicle Compare Page" : compareSEO.seo.MetaTitle)

    return (
      <Layout seo={compareSEO}>
        <VehicleCompare key={"Featured"} data={pageContent} theme={theme} mainVehicle={mainVehicle}
            selectedVehicle={selectedVehicle} thumbnails={thumbnails}></VehicleCompare>
      </Layout>
    )
}

export default Compare